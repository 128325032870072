<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ $t('edit') }} Item</h2>
        <div @click="$emit('close')" v-if="isModal">
          <b-icon class="cursor-pointer" icon="close-thick"/>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <ValidationProvider :name="$t('warehouse.product_name')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('warehouse.product_name')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="product_name" v-model="form.product_name"
                         placeholder="Enter product name"></b-input>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('category')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('category')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" no-outline expanded :items="categories"
                           label="category_name"
                           v-model="form.category" :placeholder="$t('select') + ' ' + $t('category')"></sg-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('warehouse.min_qty')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('warehouse.min_qty')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="number" id="min_qty" step="any" v-model.number="form.min_qty"
                         placeholder="0.0"></b-input>
              </b-field>
            </ValidationProvider>
          </form>

        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "UpdateItem",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    categories() {
      return this.$store.state.farm.warehouse.categories
    },
    unit() {
      return this.$store.state.farm.warehouse.unit
    },
  },
  data: () => {
    return {
      form: {}
    }
  },
  created() {
    if (this.defaultValue) {
      this.form.product_name = this.defaultValue.product_name
      this.form.category = this.categories.find(e => e.category_id === this.defaultValue.category_id)
      this.form.min_qty = this.defaultValue.min_qty
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        this.$loading()
        if (success) {
          await this.$store.dispatch('farm/updateWarehouse', {
            warehouse_id: this.defaultValue.warehouse_id,
            farm_id: this.defaultValue.farm_id,
            category_id: this.form.category.category_id,
            // mode: this.defaultValue.mode,
            desc: this.defaultValue.desc,
            product_name: this.form.product_name,
            min_qty: this.form.min_qty,
            // product_price: this.defaultValue.product_price,
            // qty: this.defaultValue.qty,
            // unit: this.defaultValue.unit,
            // synthesize: this.defaultValue.synthesize,
            // component: null,
            created_at: this.defaultValue.created_at,
          })

          this.$emit('close')
          this.$store.dispatch('farm/refreshWarehouse')
        }
        this.$loading(false)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 450px !important;
  max-width: 100%;
}
</style>

